<template>
  <topBanner />
  <!--Section Start-->
  <div class="section">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="sigma_about mt-5 mt-lg-0">
            <h5 class="title">{{ termsandconditionData.section1.heading }}</h5>
            <div class="sigma_about-content">
              <p v-for="item in termsandconditionData.section1.desc" :key="item.id">{{ item.row }}</p>   
            </div> 

            <h5 class="title">{{ termsandconditionData.section2.heading }}</h5>
            <div class="sigma_about-content">
              <p v-for="item in termsandconditionData.section2.desc" :key="item.id">{{ item.row }}</p>
            </div>

            <h5 class="title">{{ termsandconditionData.section3.heading }}</h5>
            <div class="sigma_about-content">
              <p v-for="item in termsandconditionData.section3.desc" :key="item.id">{{ item.row }}</p>
            </div>

            <h5 class="title">{{ termsandconditionData.section4.heading }}</h5>
            <div class="sigma_about-content">
              <p v-for="item in termsandconditionData.section4.desc" :key="item.id">{{ item.row }}</p>
            </div>

            <h5 class="title">{{ termsandconditionData.section5.heading }}</h5>
            <div class="sigma_about-content">
              <p v-for="item in termsandconditionData.section5.desc" :key="item.id">{{ item.row }}</p>
            </div>

            <h5 class="title">{{ termsandconditionData.section6.heading }}</h5>
            <div class="sigma_about-content">
              <p v-for="item in termsandconditionData.section6.desc" :key="item.id">{{ item.row }}</p>
            </div>

            <h5 class="title">{{ termsandconditionData.section7.heading }}</h5>
            <div class="sigma_about-content">
              <p v-for="item in termsandconditionData.section7.desc" :key="item.id">{{ item.row }}</p>
            </div>

            <h5 class="title">{{ termsandconditionData.section8.heading }}</h5>
            <div class="sigma_about-content">
              <p v-for="item in termsandconditionData.section8.desc" :key="item.id">{{ item.row }}</p> 
            </div>

            <h5 class="title">{{ termsandconditionData.section9.heading }}</h5>
            <div class="sigma_about-content">
              <p v-for="item in termsandconditionData.section9.desc" :key="item.id">{{ item.row }}</p> 
            </div>

            <h5 class="title">{{ termsandconditionData.section10.heading }}</h5>
            <div class="sigma_about-content">
              <p v-for="item in termsandconditionData.section10.desc" :key="item.id">{{ item.row }}</p> 
            </div>

            <h5 class="title">{{ termsandconditionData.section11.heading }}</h5>
            <div class="sigma_about-content">
              <p v-for="item in termsandconditionData.section11.desc" :key="item.id">{{ item.row }}</p> 
            </div>

            <h5 class="title">{{ termsandconditionData.section12.heading }}</h5>
            <div class="sigma_about-content">
              <p v-for="item in termsandconditionData.section12.desc" :key="item.id">{{ item.row }}</p> 
            </div>

            <h5 class="title">{{ termsandconditionData.section13.heading }}</h5>
            <div class="sigma_about-content">
              <p v-for="item in termsandconditionData.section13.desc" :key="item.id">{{ item.row }}</p>
            </div>

            <h5 class="title">{{ termsandconditionData.section14.heading }}</h5>
            <div class="sigma_about-content">
              <p v-for="item in termsandconditionData.section14.desc" :key="item.id">{{ item.row }}</p>
            </div>

            <h5 class="title">{{ termsandconditionData.section15.heading }}</h5>
            <div class="sigma_about-content">
              <p v-for="item in termsandconditionData.section15.desc" :key="item.id">{{ item.row }}</p>
            </div>

            <h5 class="title">{{ termsandconditionData.section16.heading }}</h5>
            <div class="sigma_about-content">
              <p v-for="item in termsandconditionData.section16.desc" :key="item.id">{{ item.row }}</p>
            </div>

            <h5 class="title">{{ termsandconditionData.section17.heading }}</h5>
            <div class="sigma_about-content">
              <p v-for="item in termsandconditionData.section17.desc" :key="item.id">{{ item.row }}</p>
            </div>  
          </div>
        </div>
      </div>
      <!--End of row-->
    </div>
  </div>
  <!--Section End-->
</template>

<script>
import data from '../Data/data.json'
import topBanner from "../components/innerpagestopbanner/innerTop.vue";
export default {
  components: {
    topBanner,
  },
  data() {
    return {
      termsandconditionData: ''
    };
  },
  created(){
    this.termsandconditionData = data.term_and_conditions
  }
};
</script>
